import { MeetingTypes } from '../../contexts';

export const meetingTypes = [
  {
    type: MeetingTypes.VIDEO,
    gapLengthHours: 0.5,
    defaultEmployeeIds: '858', // replace with employeeIds from albertsstäd
  },
  {
    type: MeetingTypes.PHYSICAL,
    gapLengthHours: 1,
    defaultEmployeeIds: '858', // replace with employeeIds from albertsstäd
  },
];
