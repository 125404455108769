import { format } from "date-fns";
import { sv } from "date-fns/locale";
import { IHomeCleaningForm } from "../../contexts";

const getYear = (date: string) => {
  return date.slice(0, 4);
};
const getMonth = (date: string) => {
  return date.slice(4, 6);
};
const getDate = (date: string) => {
  return date.slice(6);
};

export const stringDateToDateObject = (date: string) => {
  const dateObject = new Date();
  dateObject.setDate(Number(getDate(date)));
  dateObject.setMonth(Number(getMonth(date)) - 1);
  dateObject.setFullYear(Number(getYear(date)));
  return dateObject;
};

export const nextWeekStartDateObject = (date: string) => {
  const dateObject = new Date();
  dateObject.setDate(Number(getDate(date)));
  dateObject.setMonth(Number(getMonth(date)) - 1);
  dateObject.setFullYear(Number(getYear(date)));

  const oneDayInMilliSec = 24 * 60 * 60 * 1000;

  let dateMilli = dateObject.getTime() + 1 * oneDayInMilliSec;
  let nextWeekStartDateObj = new Date(dateMilli);

  return nextWeekStartDateObj;
};

export const getWeekDay = (date: string) => {
  const dateObject = stringDateToDateObject(date);
  return dateObject.getDay();
};

export const getUnixDate = (date: string, start: number, end: number) => {
  const dateObject = stringDateToDateObject(date);
  let dateMilli = dateObject.getTime();
  // let dateSec = Math.floor(dateMilli / 1000);

  const oneDayInMilliSec = 24 * 60 * 60 * 1000;

  let startMili = dateMilli - start * oneDayInMilliSec;
  let endMili = dateMilli + end * oneDayInMilliSec;

  let startSec = Math.floor(startMili / 1000);
  let endSec = Math.floor(endMili / 1000);
  return {
    startDateUnix: startSec,
    endDateUnix: endSec,
  };
};

export const getNextDate = (date: string) => {
  const dateObject = stringDateToDateObject(date);
  const day = dateObject.getDay();
  const time = dateObject.getTime();
  const oneDayInMilliSec = 24 * 60 * 60 * 1000;
  const nextDateInMilliSec =
    day < 5 ? time + oneDayInMilliSec : time + 3 * oneDayInMilliSec;
  const nextDateObject = new Date(nextDateInMilliSec);
  const nextDate =
    nextDateObject.getDate() < 10
      ? "0" + nextDateObject.getDate()
      : nextDateObject.getDate();
  const nextMonth =
    nextDateObject.getMonth() + 1 < 10
      ? "0" + (nextDateObject.getMonth() + 1)
      : nextDateObject.getMonth() + 1;
  const year = nextDateObject.getFullYear();

  const ddd = "" + year + nextMonth + nextDate;

  return ddd;
};

export const getPrevDate = (date: string, diff: number) => {
  const dateObject = stringDateToDateObject(date);
  // const day = dateObject.getDay();
  const time = dateObject.getTime();
  const oneDayInMilliSec = 24 * 60 * 60 * 1000;
  const prevDateInMilliSec = time - diff * oneDayInMilliSec;
  const prevDateObject = new Date(prevDateInMilliSec);
  const nextDate =
    prevDateObject.getDate() < 10
      ? "0" + prevDateObject.getDate()
      : prevDateObject.getDate();
  const nextMonth =
    prevDateObject.getMonth() + 1 < 10
      ? "0" + (prevDateObject.getMonth() + 1)
      : prevDateObject.getMonth() + 1;
  const year = prevDateObject.getFullYear();

  const ddd = "" + year + nextMonth + nextDate;

  return ddd;
};

export const formatUserInputDate = (inputDate: number) => {
  const stringDate = JSON.stringify(inputDate);
  const year = getYear(stringDate);
  const month = getMonth(stringDate);
  const date = getDate(stringDate);

  return `${year}-${month}-${date}`;
};

export const formatDisplayDate = (stringDate: string) => {
  // Example input: '20221210'
  // Example output: 'LÖRDAG 10 DECEMBER'(but letters are in smaller case)
  const date = stringDateToDateObject(stringDate);
  const formattedDate = format(date.getTime(), "EEEE d MMMM", { locale: sv });
  return formattedDate.toLowerCase().replace(/\./g, "");
};

// export const calculateMonthlyApiTimeIntervalUnix = (pagination: number) => {
//   const oneDayInMilliSec = 24 * 60 * 60 * 1000;
//   const twentySevenDaysInMilliSec = oneDayInMilliSec * 27; // four weeks minus one day

//   let startDateMilli = new Date(Date.now() + oneDayInMilliSec).getTime(); // tomorrow
//   const weeksAheadMilli = twentySevenDaysInMilliSec * pagination;
//   const daysAheadMilli = oneDayInMilliSec * pagination;

//   startDateMilli += weeksAheadMilli + daysAheadMilli;

//   const endDateMilli = startDateMilli + twentySevenDaysInMilliSec;

//   const startDateUnix = Math.floor(startDateMilli / 1000);
//   const endDateUnix = Math.floor(endDateMilli / 1000);

//   return { endDateUnix, startDateUnix }; // an interval of 28 days (four weeks)
// };

export const calculateMonthlyApiTimeIntervalUnix = (startDateObj: Date) => {
  const oneDayInMilliSec = 24 * 60 * 60 * 1000;
  const twentySevenDaysInMilliSec = oneDayInMilliSec * 37; // four weeks minus one day

  let startDateMilli = startDateObj.getTime();

  const endDateMilli = startDateMilli + twentySevenDaysInMilliSec;

  const startDateUnix = Math.floor(startDateMilli / 1000);
  let endDateUnix = Math.floor(endDateMilli / 1000);

  // const endWeekDay = new Date(endDateUnix).getDay();

  // if (endWeekDay < 5) {
  //   endDateUnix = endDateUnix + (5 - endWeekDay) * 24 * 60 * 60;
  // }

  return { endDateUnix, startDateUnix }; // an interval of 28 days (four weeks)
};

export const formattedDateForCalendar = (
  date: string,
  dateNumber: string,
  monthNumber: string
) => {
  let day;
  let month;
  if (dateNumber.charAt(0) === "0") {
    day = dateNumber.charAt(1);
  } else {
    day = dateNumber;
  }
  if (monthNumber.charAt(0) === "0") {
    month = monthNumber.charAt(1);
  } else {
    month = monthNumber;
  }
  const formattedDate = `${date.substring(0, 3)} ${day}/${month}`;
  return formattedDate;
};

export const getRightActiveStep = (data: IHomeCleaningForm) => {
  if (!data.sqmSize && !data.address && !data.startTime && !data.firstName) {
    return 0;
  } else if (
    data.sqmSize &&
    data.address &&
    !data.startTime &&
    !data.firstName
  ) {
    return 1;
  } else if (
    data.sqmSize &&
    data.address &&
    data.startTime &&
    !data.firstName
  ) {
    return 2;
  } else if (
    data.sqmSize &&
    data.address &&
    data.startTime &&
    data.firstName &&
    data.lastName &&
    data.phone &&
    data.email
  ) {
    return 3;
  } else {
    return 0;
  }
};
