import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import _ from "lodash";

import { GET_AVAILABLE_TIME_GAPS } from "../../gql";
import {
  calculateMonthlyApiTimeIntervalUnix,
  formatDisplayDate,
  formatUserInputDate,
  getWeekDay,
  getNextDate,
  getPrevDate,
  nextWeekStartDateObject,
  formattedDateForCalendar,
  stringDateToDateObject,
} from "./helpers";
import { ICalendar, ITimeGap } from "./types";
import { CalendarItem } from "./components";
import { useFormContext } from "../../hooks";
import { IMeetingInformation } from "../../contexts";
import useStyles from "./styles";
import { VALIDATION_ERROR_UPPSALA } from "../../helpers";
import { ArrowLeftIcon, ArrowRightIcon } from "../icons";

export const Calendar = ({
  gapLengthHours,
  forwardPaginationText,
  backwardsPaginationText,
  apiErrorText,
  apiErrorTextUppsala,
  bookThroughOldForm,
  oldFormUrl,
  here,
  noSelectedDateErrorText,
  meetingType,
  handleScrollToButton,
  activeStepId,
  setIsCalendarDataFetched,
}: ICalendar) => {
  const classes = useStyles();
  const {
    homeCleaningFormValues,
    setFormValues,
    fieldErrors,
    timewaveInformationState,
  } = useFormContext();

  const removeLastThreeKeys = (data: any, lastEl: number): any => {
    const keys = Object.keys(data);
    keys.sort(); // Ensure keys are sorted (optional, depending on your specific use case)
    const keysToRemove = keys.slice(-lastEl); // Get the last three keys
    const newData: any = { ...data };

    keysToRemove.forEach((key) => {
      delete newData[key];
    });

    return newData;
  };
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so add 1
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}${month}${day}`;
  };

  const [timeGaps, setTimeGaps] = useState<Record<string, ITimeGap[]>>({});
  const [localPagination, setLocalPagination] = useState<number>(0);
  const [apiPagination, setApiPagination] = useState<number>(0);
  const [noSelectedDateError, setNoSelectedDateError] = useState(false);
  const [lastStartDate, setLastStartDate] = useState<string>("");
  const [getTimeGaps, { loading, error, refetch }] = useLazyQuery(
    GET_AVAILABLE_TIME_GAPS,
    {
      onCompleted: (data) => {
        const timeGaps = data.getAvailableTimeGaps;
        let groupedData: any = {};
        const groupedDataa = _.groupBy(timeGaps, "startDate");
        const dateKeys = Object.keys(groupedDataa);

        let groupedDataReducedArray = removeLastThreeKeys(
          groupedDataa,
          dateKeys.length % 5
        );

        if (dateKeys.length % 5 !== 0) {
          setTimeGaps((prevState) => ({
            ...prevState,
            ...groupedDataReducedArray,
          }));
        } else {
          setTimeGaps((prevState) => ({
            ...prevState,
            ...groupedDataa,
          }));
        }

        const shortenedKeys = dateKeys.slice(
          0,
          dateKeys.length - (dateKeys.length % 5)
        );
        const sortedDateKeys = shortenedKeys.sort();
        // const smallestDate = sortedDateKeys[0];
        const biggestDate = sortedDateKeys[sortedDateKeys.length - 1];

        const year = parseInt(biggestDate.substring(0, 4), 10);
        const month = parseInt(biggestDate.substring(4, 6), 10) - 1; // Months are zero-based
        const day = parseInt(biggestDate.substring(6, 8), 10);
        const date = new Date(year, month, day);
        date.setDate(date.getDate() + 1);

        setLastStartDate(biggestDate);

        let week = 0;
        let i = 0;
        let j = 0;
        let pom = [];
        let lastDate = "";
        while (j < timeGaps.length) {
          let day = getWeekDay(timeGaps[j].startDate.toString());

          if (i + 1 === day + week) {
            lastDate = timeGaps[j].startDate.toString();
            pom.push(timeGaps[j]);
            groupedData[timeGaps[j].startDate] = pom;
            j++;
          } else {
            if (pom.length > 0) {
              pom = [];
              i++;
            } else {
              if (lastDate === "") {
                let prevDate = getPrevDate(
                  timeGaps[j].startDate.toString(),
                  day - (i + 1)
                );
                lastDate = prevDate;
                groupedData[prevDate] = [];
                pom = [];
                i++;
              } else {
                let nextDate = getNextDate(lastDate);
                lastDate = nextDate;
                groupedData[nextDate] = [];
                pom = [];
                i++;
              }
            }

            if (i % 5 === 0) {
              week = week + 5;
            }
          }
        }

        let lastDay = getWeekDay(
          timeGaps[timeGaps.length - 1].startDate.toString()
        );

        if (lastDay < 5) {
          for (let c = 0; c < 5 - lastDay; c++) {
            let nextDate = getNextDate(lastDate);
            lastDate = nextDate;
            groupedData[nextDate] = [];
            pom = [];
          }

          //   let nextDate = getNextDate(lastDate);
          //   const { endDateUnix, startDateUnix } = getUnixDate(
          //     timeGaps[timeGaps.length - 1].startDate.toString(),
          //     lastDay - 1,
          //     5 - lastDay
          //   );
          //   refetch({
          //     startDateUnix,
          //     endDateUnix,
          //   });
        }

        // setLastStartDate(lastDate);
        // setTimeGaps((prevState) => ({
        //   ...prevState,
        //   ...groupedData,
        // }));
        setIsCalendarDataFetched(true);
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (
      fieldErrors.startDate ||
      fieldErrors.startTime ||
      fieldErrors.endTime ||
      fieldErrors.meetingEmployeeId
    ) {
      setNoSelectedDateError(true);
    } else {
      setNoSelectedDateError(false);
    }
  }, [fieldErrors]);

  // useEffect(() => {
  //   const d = new Date().getTime() + 24 * 60 * 60 * 1000;
  //   const t = new Date(d);
  //   const { endDateUnix, startDateUnix } =
  //     calculateMonthlyApiTimeIntervalUnix(t);
  //   getTimeGaps({
  //     variables: {
  //       gapLengthHours,
  //       startDateUnix,
  //       endDateUnix,
  //       meetingType,
  //       postalCode: homeCleaningFormValues.postalCode,
  //       employeeIdsConfig: timewaveInformationState.employeeIdsConfig,
  //     },
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const d = new Date().getTime() + 24 * 60 * 60 * 1000;
    const t = new Date(d);
    const { endDateUnix, startDateUnix } =
      calculateMonthlyApiTimeIntervalUnix(t);

    // refetch({
    //   gapLengthHours,
    //   startDateUnix,
    //   endDateUnix,
    //   meetingType,
    //   postalCode: homeCleaningFormValues.postalCode,
    //   employeeIdsConfig: timewaveInformationState.employeeIdsConfig,
    // });
    getTimeGaps({
      variables: {
        gapLengthHours,
        startDateUnix,
        endDateUnix,
        meetingType,
        postalCode: homeCleaningFormValues.postalCode,
        employeeIdsConfig: timewaveInformationState.employeeIdsConfig,
      },
    });
    !homeCleaningFormValues.address &&
      activeStepId > 0 &&
      resetFormDataMeetingInformation();
  }, [
    homeCleaningFormValues.postalCode,
    homeCleaningFormValues.address,
    timewaveInformationState.employeeIdsConfig,
  ]);

  const setFormDataMeetingInformation = ({
    startDate,
    startTime,
    endTime,
    meetingEmployeeId,
  }: IMeetingInformation) => {
    setFormValues("startDate", startDate);
    setFormValues("startTime", startTime);
    setFormValues("endTime", endTime);
    setFormValues("meetingEmployeeId", meetingEmployeeId);
  };

  const resetFormDataMeetingInformation = () => {
    setFormValues("startDate", "");
    setFormValues("startTime", "");
    setFormValues("endTime", "");
    setFormValues("meetingEmployeeId", "");
  };

  const handleClick = (timeGap: ITimeGap) => {
    if (activeStepId >= 1) {
      handleScrollToButton();
      setFormDataMeetingInformation({
        startDate: timeGap.startDate.toString(),
        startTime: timeGap.startTime,
        endTime: timeGap.endTime,
        meetingEmployeeId: timeGap.employeeId,
      });
    }
  };

  const increasePagination = () => {
    if (localPagination + 1 >= calculateWeeksInTimeGapsState()) {
      // api call to get next four weeks
      const { endDateUnix, startDateUnix } =
        calculateMonthlyApiTimeIntervalUnix(
          nextWeekStartDateObject(lastStartDate)
        );
      refetch({
        startDateUnix,
        endDateUnix,
      });
      setApiPagination(apiPagination + 1);
      setLocalPagination(localPagination + 1);
      // resetFormDataMeetingInformation();
    } else {
      setLocalPagination(localPagination + 1);
      // resetFormDataMeetingInformation();
    }
  };

  const decreasePagination = () => {
    if (localPagination > 0) {
      setLocalPagination(localPagination - 1);
    }
    // resetFormDataMeetingInformation();
  };

  const calculateWeeksInTimeGapsState = () => {
    const days = Object.keys(timeGaps).length;
    const weeks = days / 5;
    return weeks;
  };

  const getWeeklyTimeGapKeysFromState = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedCurrentDate = `${year}${month}${day}`;
    const startNumber = localPagination * 5;
    const endNumber = localPagination * 5 + 5;
    let array: string[] = [];
    let index: number = 0;
    for (let i = 0; i < Object.keys(timeGaps).length; i++) {
      if (Object.keys(timeGaps)[i] === formattedCurrentDate) {
        index = i + 1;
        // array = Object.keys(timeGaps).slice(
        //   i + 1,
        //   Object.keys(timeGaps).length
        // );
      }
    }
    // return array.slice(startNumber, endNumber);
    if (index > 0) {
      array = Object.keys(timeGaps).slice(index, Object.keys(timeGaps).length);
      return array.slice(startNumber, endNumber);
    } else {
      return Object.keys(timeGaps).slice(startNumber, endNumber);
    }
  };

  if (
    loading &&
    homeCleaningFormValues.address &&
    homeCleaningFormValues.postalCode
  ) {
    return (
      <Box className={`${classes.centerContent} ${classes.loaderContainer}`}>
        <CircularProgress size={50} color="primary" />
      </Box>
    );
  }

  if (!homeCleaningFormValues.postalCode) {
    return (
      <>
        <Typography className={classes.centerContent}>
          Fyll i din adress och storlek på din bostad för att visa lediga tider.
        </Typography>
      </>
    );
  }

  if (
    error &&
    homeCleaningFormValues.address &&
    homeCleaningFormValues.postalCode
  ) {
    //VALIDATION_ERROR_UPPSALA is used as a validation-lookup in backend.
    return (
      <>
        {error?.message === VALIDATION_ERROR_UPPSALA ? (
          <Typography className={classes.centerContent}>
            {apiErrorTextUppsala}
          </Typography>
        ) : (
          <>
            <Typography className={classes.centerContent}>
              {apiErrorText}
            </Typography>
            <Box className={classes.linkContainer}>
              <Typography className={classes.link}>
                {bookThroughOldForm}
              </Typography>
              <Link target="_blank" href={oldFormUrl} className={classes.link}>
                {here}
              </Link>
            </Box>
          </>
        )}
      </>
    );
  }

  return (
    <>
      {!error &&
        homeCleaningFormValues.address &&
        homeCleaningFormValues.postalCode && (
          <Box className={classes.buttonContainer}>
            <button className={classes.leftPart} onClick={decreasePagination}>
              <ArrowLeftIcon className={classes.arrowIcon} />
              <Typography className={classes.arrowText} ml={2}>
                Föregående vecka
              </Typography>
            </button>
            <button className={classes.rightPart} onClick={increasePagination}>
              <Typography className={classes.arrowText} mr={2}>
                Nästa vecka
              </Typography>
              <ArrowRightIcon className={classes.arrowIcon} />
            </button>
          </Box>
        )}
      <Box className={classes.calendarContainer}>
        {getWeeklyTimeGapKeysFromState().map((date, index) => (
          <Box className={classes.dateContainer} key={index}>
            <Typography
              className={classes.dateText}
              color={
                homeCleaningFormValues.startDate ===
                `${date.substring(0, 4)}-${date.substring(
                  4,
                  6
                )}-${date.substring(6, 8)}`
                  ? "#1F3157"
                  : "#77839D"
              }
              fontWeight={
                homeCleaningFormValues.startDate ===
                `${date.substring(0, 4)}-${date.substring(
                  4,
                  6
                )}-${date.substring(6, 8)}`
                  ? 700
                  : 400
              }
            >
              {
                formattedDateForCalendar(
                  `${formatDisplayDate(date).substring(0, 9)}.`,
                  date.substring(6, date.length),
                  date.substring(4, 6)
                )
                // formatDisplayDate(timeGaps[date][0].startDate.toString())
              }
            </Typography>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={1}
              className={classes.itemsContainer}
              columns={5}
            >
              {timeGaps[date].map((timeGap, index) => (
                <CalendarItem
                  key={index}
                  chosenStartDate={homeCleaningFormValues.startDate}
                  chosenStartTime={homeCleaningFormValues.startTime}
                  timeGap={timeGap}
                  handleClick={handleClick}
                />
              ))}
            </Grid>
          </Box>
        ))}
        {noSelectedDateError && (
          <Typography className={classes.errorText}>
            {noSelectedDateErrorText}
          </Typography>
        )}
      </Box>
    </>
  );
};
