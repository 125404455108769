import React, { ReactNode, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { FormContext } from "./context";
import {
  IContactInformation,
  IFormSubmissionState,
  IHomeCleaningForm,
  IMeetingInformation,
  ITimewaveInformationState,
  MeetingTypes,
  ValueOf,
} from "./types";
import { CREATE_QUOTE_HOME_CLEANING } from "../../gql";
import { EmailType, SendEmail } from "../../helpers/sendEmail";
import { getServiceInfo } from "../../services/wordpress/wordpress";

interface IFormProvider {
  children: ReactNode;
}

export const FormProvider = ({ children }: IFormProvider) => {
  const [
    createQuoteHomeCleaning,
    {
      data: homeFormSubmissionData,
      error: homeFormSubmissionError,
      loading: homeFormSubmissionLoading,
    },
  ] = useMutation(CREATE_QUOTE_HOME_CLEANING);
  const [formSubmissionState, setFormSubmissionState] =
    useState<IFormSubmissionState>({
      loading: false,
      data: false,
      error: false,
    });
  const [isInternalEmailSent, setIsInternalEmailSent] =
    useState<boolean>(false);
  const [isCustomerEmailSent, setIsCustomerEmailSent] =
    useState<boolean>(false);

  useEffect(() => {
    if (homeFormSubmissionLoading) {
      setFormSubmissionState((prevState) => ({
        ...prevState,
        loading: true,
      }));
    }

    if (homeFormSubmissionError) {
      setFormSubmissionState({
        error: true,
        loading: false,
        data: false,
      });
    }

    if (homeFormSubmissionData) {
      setFormSubmissionState({
        data: true,
        loading: false,
        error: false,
      });
    }
  }, [
    homeFormSubmissionData,
    homeFormSubmissionError,
    homeFormSubmissionLoading,
  ]);

  // useEffect(() => {
  //   window.addEventListener("message", (event) => {
  //     //event is set in header.php in Alberts theme. If changes are made don't forget to update here as well.
  //     if (event.data.source === "albertsstad") {
  //       if (event.data.data.employeeIdsConfig) {
  //         setTimewaveInformationStates((prevValue) => ({
  //           ...prevValue,
  //           employeeIdsConfig: event.data.data.employeeIdsConfig,
  //         }));
  //       }
  //       if (event.data.data.serviceIdsConfig) {
  //         setTimewaveInformationStates((prevValue) => ({
  //           ...prevValue,
  //           serviceIdsConfig: event.data.data.serviceIdsConfig,
  //         }));
  //       }
  //       if (event.data.data.postalCode || event.data.data.sqmSize) {
  //         setHomeCleaningFormValues((prevValue) => ({
  //           ...prevValue,
  //           postalCode: event.data.data.postalCode,
  //           sqmSize: event.data.data.sqmSize,
  //           city: event.data.data.generatedCity,
  //         }));
  //       }
  //     }
  //   });
  // }, []);

  useEffect(() => {
    getServiceInofFromWP();
  }, []);

  useEffect(() => {
    if (
      isInternalEmailSent &&
      isCustomerEmailSent &&
      formSubmissionState.data
    ) {
      window.location.href =
        "https://www.bettysstad.se/offert/hemstadning/tack/";
    }
  }, [isInternalEmailSent, isCustomerEmailSent]);

  const getServiceInofFromWP = async () => {
    const serviceInfo = await getServiceInfo();
    setTimewaveInformationStates({
      employeeIdsConfig: {
        responsibleEmployeeId: serviceInfo.data.responsibleEmployeeId,
        physicalMeetingEmployeeIds: {
          gothenburg: serviceInfo.data.physicalMeetingEmployeeId1,
          stockholm: serviceInfo.data.physicalMeetingEmployeeId2,
        },
        digitalMeetingEmployeeIds: {
          gothenburg: serviceInfo.data.digitalMeetingEmployeeId1,
          stockholm: serviceInfo.data.digitalMeetingEmployeeId2,
          uppsala: serviceInfo.data.digitalMeetingEmployeeId3,
        },
      },
      serviceIdsConfig: {
        physicalMeetingServiceIds: {
          gothenburg: serviceInfo.data.physicalMeetingServiceId1,
          stockholm: serviceInfo.data.physicalMeetingServiceId2,
        },
        digitalMeetingServiceIds: {
          gothenburg: serviceInfo.data.digitalMeetingServiceId1,
          stockholm: serviceInfo.data.digitalMeetingServiceId2,
          uppsala: serviceInfo.data.digitalMeetingServiceId3,
        },
      },
    });
  };

  const [timewaveInformationState, setTimewaveInformationStates] =
    useState<ITimewaveInformationState>({
      employeeIdsConfig: {
        responsibleEmployeeId: "",
        physicalMeetingEmployeeIds: { gothenburg: "", stockholm: "" },
        digitalMeetingEmployeeIds: {
          gothenburg: "",
          stockholm: "",
          uppsala: "",
        },
      },
      serviceIdsConfig: {
        physicalMeetingServiceIds: {
          gothenburg: "",
          stockholm: "",
        },
        digitalMeetingServiceIds: {
          gothenburg: "",
          stockholm: "",
          uppsala: "",
        },
      },
    });

  const [fieldErrors, setFieldErrors] = useState<
    Record<keyof IContactInformation | keyof IMeetingInformation, boolean>
  >({
    firstName: false,
    lastName: false,
    address: false,
    city: false,
    postalCode: false,
    phone: false,
    email: false,
    sqmSize: false,
    startDate: false,
    startTime: false,
    endTime: false,
    meetingEmployeeId: false,
  });

  const [homeCleaningFormValues, setHomeCleaningFormValues] =
    useState<IHomeCleaningForm>({
      meetingType: MeetingTypes.VIDEO,
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      postalCode: "",
      phone: "",
      email: "",
      sqmSize: "",
      meetingEmployeeId: "",
      startDate: "",
      startTime: "",
      endTime: "",
    });

  const setFormValues = (
    key: Partial<keyof IHomeCleaningForm>,
    value: Partial<ValueOf<IHomeCleaningForm>>
  ) => {
    setHomeCleaningFormValues((prevValue) => ({
      ...prevValue,
      [key]: value,
    }));
  };

  const formValidationKeys: Record<
    number,
    Array<keyof IContactInformation | keyof IMeetingInformation>
  > = {
    0: ["sqmSize", "postalCode", "address"], // "city"
    1: ["meetingEmployeeId", "startDate", "startTime", "endTime"],
    2: ["firstName", "lastName", "phone", "email"],
  };

  const validationRules: Partial<
    Record<keyof IContactInformation | keyof IMeetingInformation, RegExp>
  > = {
    email: /^\S+@\S+\.\S+$/, // valid email syntax
    postalCode: /^\d{5}$/, // length of 5 numbers
  };

  const testValueAgainstRule = (
    key: keyof IContactInformation | keyof IMeetingInformation,
    value: ValueOf<IHomeCleaningForm>
  ) => {
    const rule = validationRules[key];
    if (rule && typeof value === "string") {
      return rule.test(value);
    }
    return true;
  };

  const handleSetFieldError = (
    key: keyof IContactInformation | keyof IMeetingInformation,
    error: boolean
  ) => {
    setFieldErrors((prevState) => ({
      ...prevState,
      [key]: error,
    }));
  };

  const validateFields = (step: number) => {
    const validated = formValidationKeys[step].map((key) => {
      const homeCleaningValue =
        homeCleaningFormValues[
          key as keyof IContactInformation | keyof IMeetingInformation
        ];
      if (!homeCleaningValue || !testValueAgainstRule(key, homeCleaningValue)) {
        handleSetFieldError(key, true);
        return false;
      } else {
        handleSetFieldError(key, false);
        return true;
      }
    });
    return validated.every((bool) => bool);
  };

  const sendConfirmationEmails = () => {
    SendEmail(
      {
        emailType: EmailType.CUSTOMER,
        firstName: homeCleaningFormValues.firstName,
        lastName: homeCleaningFormValues.lastName,
        startDate: homeCleaningFormValues.startDate,
        startTime: homeCleaningFormValues.startTime,
        meetingType: homeCleaningFormValues.meetingType,
        email: homeCleaningFormValues.email,
      },
      setIsInternalEmailSent,
      setIsCustomerEmailSent
    );
    SendEmail(
      {
        emailType: EmailType.INTERNAL,
        adress: homeCleaningFormValues.address,
        city: homeCleaningFormValues.city,
        email: homeCleaningFormValues.email,
        endTime: homeCleaningFormValues.endTime,
        firstName: homeCleaningFormValues.firstName,
        lastName: homeCleaningFormValues.lastName,
        meetingEmployeeId: homeCleaningFormValues.meetingEmployeeId,
        meetingType: homeCleaningFormValues.meetingType,
        phone: homeCleaningFormValues.phone,
        postalCode: homeCleaningFormValues.postalCode,
        sqmSize: homeCleaningFormValues.sqmSize,
        startDate: homeCleaningFormValues.startDate,
        startTime: homeCleaningFormValues.startTime,
      },
      setIsInternalEmailSent,
      setIsCustomerEmailSent
    );
  };

  const submitForm = () => {
    createQuoteHomeCleaning({
      variables: {
        userInput: homeCleaningFormValues,
        responsibleEmployeeId:
          timewaveInformationState.employeeIdsConfig.responsibleEmployeeId,
        serviceIdsConfig: timewaveInformationState.serviceIdsConfig,
      },
    });
  };

  const contextConfig = {
    formSubmissionState,
    submitForm,
    fieldErrors,
    validateFields,
    setFormValues,
    homeCleaningFormValues,
    timewaveInformationState,
    setTimewaveInformationStates,
    sendConfirmationEmails,
  };

  return (
    <FormContext.Provider value={contextConfig}>
      {children}
    </FormContext.Provider>
  );
};
