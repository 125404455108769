import { useState } from "react";
import { Box, Typography } from "@mui/material";

import { IButtonGroup } from "./types";
import { SubmitButton } from "../SubmitButton";
import stringData from "../../assets/data/formStringsData.json";
import { useFormContext } from "../../hooks";
import useStyles from "./styles";

export const ButtonGroup = ({
  submitForm,
  handleDisplayErrorMessage,
  activeStep,
  displayErrorMessage,
  stepsLength,
  isApproved,
  setActiveStepId,
  setIsApproved,
}: IButtonGroup) => {
  const classes = useStyles();
  const { validateFields } = useFormContext();
  const [showErrormessage, setShowErrorMessage] = useState(false);
  const formStrings = stringData.form;
  let width = window.innerWidth;

  const handleSubmit = () => {
    if (
      validateFields(0) &&
      validateFields(1) &&
      validateFields(2) &&
      isApproved
    ) {
      submitForm();
    } else if (!isApproved) {
      setShowErrorMessage(true);
    } else if (!validateFields(0) || !validateFields(1) || !validateFields(2)) {
      handleDisplayErrorMessage(true);
    }
  };

  return (
    <Box
      className={classes.buttonGroup}
      mt={width > 799 ? -8 : 2.5}
      ml={15}
      mb={{ xs: 1, sm: 0 }}
    >
      {displayErrorMessage && (
        <Box textAlign={"center"}>
          <Typography variant="body2" color={"red"}>
            {formStrings.errorHelperText}
          </Typography>
        </Box>
      )}
      {showErrormessage && (
        <Typography className={classes.error} mb={2}>
          {formStrings.integrityPolicy.error}
        </Typography>
      )}
      <SubmitButton text={formStrings.buttons.submit} onClick={handleSubmit} />
    </Box>
  );
};
