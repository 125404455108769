import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      textAlign: "center",
      width: "fit-content",
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    klartStepConnector: {
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 800px)": {
        display: "none",
      },
    },
    klartStepContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      maxWidth: "660px",
      minWidth: "660px",
      "@media (max-width: 800px)": {
        maxWidth: "100%",
        minWidth: "100%",
        height: "auto",
        boxShadow: "none",
        padding: "0px 32px",
      },
      "@media (max-width: 600px)": {
        maxWidth: "100%",
        minWidth: "100%",
        height: "auto",
        boxShadow: "none",
        padding: "0px 18px",
      },
    },
  })
);
