import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    calendarContainer: {
      display: "flex",
      flexDirection: "row",
    },
    dateContainer: {
      marginBottom: 10,
      padding: "0 10px",
      flex: 1,
      "@media (max-width: 600px)": {
        padding: "0 5px",
      },
    },
    itemsContainer: {
      marginBottom: 25,
      display: "flex",
      flexDirection: "column",
    },
    dateText: {
      marginBottom: 20,
      fontSize: "0.875rem",
      textAlign: "center",
      transition: ".4s ease-out",
      minWidth: "55px",
      "@media (max-width: 600px)": {
        fontSize: "0.750rem",
        width: "60px",
      },
    },
    loaderContainer: {
      padding: "25px 0",
    },
    centerContent: {
      paddingLeft: "15px",
      marginLeft: -3,
      "@media (min-width: 420px)": {
        paddingLeft: 0,
        marginLeft: 0,
      },
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 30,
      "@media (max-width: 600px)": {
        marginBottom: 10,
      },
    },
    leftPart: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      border: "none",
      background: "transparent",
      cursor: "pointer",
      "&:hover": {
        "& p": {
          color: "#1f3157",
          transition: "color .4s ease-out",
        },
        "& svg": {
          fill: "#1f3157",
          transition: "fill .4s ease-out",
        },
      },
    },
    rightPart: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      border: "none",
      background: "transparent",
      cursor: "pointer",
      "&:hover": {
        "& p": {
          color: "#1f3157",
          transition: "color .4s ease-out",
        },
        "& svg": {
          fill: "#1f3157",
          transition: "fill .4s ease-out",
        },
      },
    },
    arrowText: {
      color: "#77839D",
    },
    arrowIcon: {
      fill: "#77839D",
    },
    errorText: {
      color: theme.palette.error.main,
    },
    linkContainer: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "11px",
      "@media (min-width: 420px)": {
        paddingLeft: 0,
        marginLeft: -2,
      },
    },
    link: {
      color: theme.palette.secondary.main,
      textDecorationColor: theme.palette.secondary.main,
      fontWeight: 700,
      marginRight: 5,
    },
  })
);
