import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    check: {
      display: "flex",
      background: "#1F3157",
      color: "white",
      borderRadius: "16px",
      height: "22.5px",
      width: "22.5px",
      padding: "6px 12px 6px 12px",
      justifyContent: "center",
      alignItems: "center",
      // Checkmark icon
      "&::before": {
        content: '"C"',
        fontFamily: "alberts",
        fontSize: 13,
        [theme.breakpoints.only("xs")]: {
          fontSize: 9,
        },
      },
    },
    loadingText: {
      animation: "$loading 1s linear infinite",
    },
    "@keyframes loading": {
      // Define keyframes
      "0%": { opacity: 0.5 },
      "50%": { opacity: 1 },
      "100%": { opacity: 0.5 },
    },
  })
);
