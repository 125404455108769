import React from "react";
import { Stepper, Step, StepLabel, Box } from "@mui/material";

import { StyledConnector, StepIcon, ICustomStepper } from "./index";
import useStyles from "./styles";

export const CustomStepper = ({
  activeStep,
  steps,
  orientation,
}: ICustomStepper) => {
  const classes = useStyles();

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel={orientation ? false : true}
      connector={<StyledConnector />}
      orientation={orientation ? orientation : "horizontal"}
    >
      {steps.map((step, index) => {
        const stepProps: {
          completed?: boolean;
          active?: boolean;
          disabled?: boolean;
        } = {};
        return (
          <Step
            key={index}
            {...stepProps}
            className={classes.step}
            expanded={true}
          >
            <StepLabel
              className={classes.stepLabel}
              classes={{
                completed: classes.active,
                active: classes.active,
                disabled: classes.disabled,
                label: classes.stepLabel,
              }}
              StepIconComponent={StepIcon}
            ></StepLabel>
            <Box key={index} id={`form${index}`} ml={5}>
              {step.component}
            </Box>
          </Step>
        );
      })}
    </Stepper>
  );
};
