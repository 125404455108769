import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    item: {
      padding: "2px 28px",
      borderRadius: 30,
      cursor: "pointer",
      textAlign: "center",
      height: "35px",
      width: "100px",
      "@media (max-width: 760px)": {
        height: "30px",
        width: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "@media (max-width: 600px)": {
        height: "30px",
        width: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      // [theme.breakpoints.down("sm")]: {
      //   padding: "10px 0px",
      //   fontSize: "0.9rem",
      // },
      // [theme.breakpoints.down("md")]: {
      //   padding: "5px 0px",
      //   fontSize: "0.85rem",
      //   width: "90px",
      // },
      // [theme.breakpoints.down("lg")]: {
      //   fontSize: "1.1rem",
      // },
    },
    notChoosenItem: {
      border: `1px ${theme.palette.grey[400]} solid`,
      backgroundColor: "#F0F1F4",
      transition: ".4s ease-out",
    },
    choosenItem: {
      border: "1px solid #1F3157",
      backgroundColor: "#1F3157",
      transition: ".4s ease-out",
    },
  })
);
