import React from "react";
import { Typography } from "@mui/material";

import useStyles from "./styles";

interface ISubmitButton {
  text: string;
  onClick: () => void;
}

export const SubmitButton = ({ text, onClick }: ISubmitButton) => {
  const classes = useStyles();

  return (
    <button className={classes.button} onClick={onClick}>
      <Typography className={classes.text}>{text}</Typography>
    </button>
  );
};
