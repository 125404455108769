import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
    },
    loadingContainer: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    errorText: {
      color: theme.palette.error.main,
      width: "fit-content",
      margin: "0 auto 5px auto",
    },
    linkContainer: {
      display: "flex",
      width: "fit-content",
      margin: "0 auto 20px auto",
    },
    link: {
      color: theme.palette.secondary.main,
      textDecorationColor: theme.palette.secondary.main,
      marginLeft: 5,
      fontWeight: 700,
    },
    topPartCard: {
      backgroundColor: "#1F3157",
      width: "100%",
      height: "77px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderTopRightRadius: "8px",
      borderTopLeftRadius: "8px",
    },
    bottomPartCard: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      borderBottomRightRadius: "8px",
      borderBottomLeftRadius: "8px",
      paddingBottom: "12px",
    },
    windowElement: {
      position: "fixed",
      top: "161px",
      marginLeft: "7%",
      zIndex: 10,
    },
    customStepperDiv: {
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 800px)": {
        display: "none",
      },
    },
    onlyComponentsDiv: {
      display: "none",
      "@media (max-width: 800px)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      },
    },
    topPartCardMobile: {
      backgroundColor: "#1F3157",
      width: "100%",
      height: "77px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderTopRightRadius: "8px",
      borderTopLeftRadius: "8px",
    },
    bottomPartCardMobile: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      borderBottomRightRadius: "8px",
      borderBottomLeftRadius: "8px",
      paddingBottom: "12px",
    },
    windowElementMobile: {
      position: "fixed",
      zIndex: 10,
      bottom: 0,
      right: 0,
      // marginLeft: "auto",
      // marginRight: "auto",
    },
  })
);
