import React, { useRef } from "react";

import useStyles from "./styles";

interface IInput {
  placeholder: string;
  error: boolean;
  errorHelperText: string;
  value: string | number;
  type?: React.HTMLInputTypeAttribute;
  onChange: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
}

export const Input = ({
  placeholder,
  error,
  errorHelperText,
  type,
  value,
  onChange,
  onBlur,
  disabled,
}: IInput) => {
  const classes = useStyles();
  const input = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (input.current !== null) {
      input.current.focus();
    }
  };

  const checkIfNumber = (event: any) => {
    /**
     * Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys
     **/

    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  return (
    <div
      className={`${classes.inputContainer} ${error && classes.inputError} ${
        disabled && classes.inputDisabled
      }`}
      onClick={handleClick}
    >
      {error && <p className={classes.errorHelperText}>{errorHelperText}</p>}
      <div
        className={error ? classes.errorIconContainer : classes.innerContainer}
      >
        <input
          placeholder={placeholder}
          value={value}
          type={type ?? "text"}
          className={classes.input}
          onWheel={() => {
            input.current?.blur();
          }}
          ref={input}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          disabled={disabled}
          onKeyDown={
            type === "number" ? (event) => checkIfNumber(event) : () => {}
          }
        />
      </div>
    </div>
  );
};
