import { Box, Checkbox, Link, Typography } from "@mui/material";
import useStyles from "./styles";
import stringData from "../../../assets/data/formStringsData.json";

export const Klart = ({ activeStepId, isApproved, setIsApproved }: any) => {
  const classes = useStyles();
  const formStrings = stringData.form;
  const handleCheckbox = () => {
    setIsApproved(!isApproved);
  };
  return (
    <Box display={"flex"} flexDirection={"row"} justifyContent={"end"}>
      <Box mr={1} className={classes.klartStepConnector}>
        <Box
          height={"36px"}
          width={"88px"}
          borderRadius={"8px"}
          mr={1}
          mb={0.5}
          sx={{
            backgroundColor: activeStepId >= 3 ? "#1F3157" : "#9FADCA",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color={"white"} variant="h5" fontWeight={700}>
            Klart
          </Typography>
        </Box>
        <hr
          style={{
            marginRight: "48px",
            borderLeftWidth: "8px",
            width: 0,
            borderLeftStyle: "dotted",
            borderLeftColor:
              activeStepId >= 3 && isApproved ? "#1F3157" : "#9FADCA",
            borderTop: "none",
            borderRight: "none",
            borderBottom: "none",
            height: "50px",
          }}
        />
      </Box>
      <Box id="klartStep" className={classes.klartStepContent}>
        <Typography variant="h2" className={classes.header}>
          Vad händer nu?
        </Typography>
        <Typography variant="body2" color={"#77839D"}>
          Snart kommer du att få ett bekräftelsemejl med din bokade tid med
          länken till mötet. Frågor? Kontakta vår kundtjänst! 🚀
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          ml={-2}
        >
          <Checkbox
            checked={isApproved}
            required={true}
            onChange={handleCheckbox}
            disabled={activeStepId < 3 ? true : false}
          />
          <Typography variant="body2" color={"#77839D"}>
            {formStrings.integrityPolicy.preLink}{" "}
            <Link
              color={"#1e2f56"}
              target="_blank"
              href={formStrings.integrityPolicy.linkUrl}
            >
              integritetspolicy
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
