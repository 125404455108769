import { Box, Link } from "@mui/material";
import { BettysLogoIcon, PhoneIcon } from "../../components/icons/icons";
import LogoImage from "../../assets/images/logo.png";

export const Header = () => {
  let width = window.innerWidth;
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={"100%"}
      height={width > 599 ? "108px" : "61px"}
      sx={{ background: "white" }}
      padding={{ xs: "16px", sm: "32px" }}
      mb={7.5}
    >
      <BettysLogoIcon onClick={() => window.location.reload()} />
      <Box
        width={{ xs: "150px", sm: "210px" }}
        height={{ xs: "29px", sm: "44px" }}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        borderRadius={"71px"}
        border={"1px solid #1F3157"}
        paddingLeft={2}
        paddingRight={2}
      >
        <PhoneIcon
          fontSize="small"
          viewBox={width > 600 ? "0 0 16 16" : "0 0 24 16"}
          style={{ marginLeft: width < 600 ? "-3px" : 0 }}
        />
        <hr
          style={{
            width: "1px",
            height: width > 600 ? "28px" : "16px",
            color: "#1F3157",
            marginLeft: width < 600 ? "10px" : "20px",
          }}
        />
        <Link
          href="tel:040-23 70 07"
          color={"#1F3157"}
          sx={{ textDecoration: "none" }}
          fontSize={width < 600 ? "13px" : "20px"}
        >
          010-330 30 95
        </Link>
      </Box>
    </Box>
  );
};
