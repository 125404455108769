import React, { useEffect } from "react";
import { Box, Grid, InputLabel } from "@mui/material";

import { FormSection } from "../../FormSection";
import { Input } from "../../Input";
import { useFormContext } from "../../../hooks";
import stringData from "../../../assets/data/formStringsData.json";
import { getRightActiveStep } from "../../Calendar/helpers";

export const ContactInformation = ({ activeStepId, setActiveStepId }: any) => {
  const contactInformationStrings = stringData.steps.contactInformation;

  const { setFormValues, fieldErrors, homeCleaningFormValues, validateFields } =
    useFormContext();
  let formHeight = document.getElementById("contactForm");
  let contactFormHeight = formHeight && formHeight.offsetHeight;
  let width = window.innerWidth;

  useEffect(() => {
    if (
      homeCleaningFormValues.firstName &&
      homeCleaningFormValues.lastName &&
      homeCleaningFormValues.phone &&
      homeCleaningFormValues.email &&
      validateFields(2)
    ) {
      setActiveStepId(getRightActiveStep(homeCleaningFormValues));
    }
  }, [
    homeCleaningFormValues.firstName,
    homeCleaningFormValues.lastName,
    homeCleaningFormValues.phone,
    homeCleaningFormValues.email,
  ]);

  return (
    <Box display={"flex"} flexDirection={"row"} width={"100%"}>
      {width > 800 && (
        <hr
          style={{
            marginTop: "55px",
            marginLeft: "-25px",
            borderLeftWidth: "8px",
            width: 0,
            borderLeftStyle: "dotted",
            borderLeftColor: activeStepId > 2 ? "#1F3157" : "#9FADCA",
            borderTop: "none",
            borderRight: "none",
            borderBottom: "none",
            height: `${contactFormHeight && contactFormHeight + 78}px`,
          }}
        />
      )}
      <FormSection title={contactInformationStrings.contactInformation.title}>
        <Grid container spacing={1} id="contactForm">
          <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            ml={1}
            mr={1}
          >
            <Grid item xs={12} sm={12} md={5.8}>
              <InputLabel sx={{ color: "#77839D", marginBottom: "3px" }}>
                {
                  contactInformationStrings.contactInformation.fields.firstName
                    .placeholder
                }
              </InputLabel>
              <Input
                placeholder={
                  contactInformationStrings.contactInformation.fields.firstName
                    .placeholder
                }
                value={homeCleaningFormValues["firstName"]}
                errorHelperText={
                  contactInformationStrings.contactInformation.fields.firstName
                    .errorHelperText
                }
                error={fieldErrors.firstName}
                onChange={(value) => setFormValues("firstName", value)}
                disabled={activeStepId < 2 ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5.8} mt={{ xs: 1, sm: 0 }}>
              <InputLabel sx={{ color: "#77839D", marginBottom: "3px" }}>
                {
                  contactInformationStrings.contactInformation.fields.lastName
                    .placeholder
                }
              </InputLabel>
              <Input
                placeholder={
                  contactInformationStrings.contactInformation.fields.lastName
                    .placeholder
                }
                errorHelperText={
                  contactInformationStrings.contactInformation.fields.lastName
                    .errorHelperText
                }
                value={homeCleaningFormValues["lastName"]}
                error={fieldErrors.lastName}
                onChange={(value) => setFormValues("lastName", value)}
                disabled={activeStepId < 2 ? true : false}
              />
            </Grid>
          </Grid>
          <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            ml={1}
            mr={1}
            mt={{ xs: 1, sm: 2 }}
          >
            <Grid item xs={12} sm={12} md={5.8}>
              <InputLabel sx={{ color: "#77839D", marginBottom: "3px" }}>
                {
                  contactInformationStrings.contactInformation.fields.phone
                    .placeholder
                }
              </InputLabel>
              <Input
                placeholder={
                  contactInformationStrings.contactInformation.fields.phone
                    .placeholder
                }
                errorHelperText={
                  contactInformationStrings.contactInformation.fields.phone
                    .errorHelperText
                }
                type="number"
                value={homeCleaningFormValues["phone"]}
                error={fieldErrors.phone}
                onChange={(value) => setFormValues("phone", value)}
                disabled={activeStepId < 2 ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5.8} mt={{ xs: 1, sm: 0 }}>
              <InputLabel sx={{ color: "#77839D", marginBottom: "3px" }}>
                {
                  contactInformationStrings.contactInformation.fields.email
                    .placeholder
                }
              </InputLabel>
              <Input
                placeholder={
                  contactInformationStrings.contactInformation.fields.email
                    .placeholder
                }
                errorHelperText={
                  contactInformationStrings.contactInformation.fields.email
                    .errorHelperText
                }
                error={fieldErrors.email}
                value={homeCleaningFormValues["email"]}
                onChange={(value) => setFormValues("email", value)}
                disabled={activeStepId < 2 ? true : false}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormSection>
    </Box>
  );
};
