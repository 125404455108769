import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    buttonGroup: {
      display: "flex",
      alignItems: "end",
      flexDirection: "column",
      "@media (max-width: 800px)": {
        width: "80%",
      },
      "@media (max-width: 600px)": {
        width: "70%",
      },
      "@media (max-width: 460px)": {
        width: "65%",
      },
      "@media (max-width: 350px)": {
        width: "55%",
      },
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center",
      padding: 0,
    },
    checkbox: {
      color: theme.palette.grey[500],
    },
    error: {
      color: theme.palette.warning.dark,
      fontWeight: 700,
      fontSize: 13,
    },
    submitButton: {
      marginTop: "1.625rem",
    },
    link: {
      color: theme.palette.secondary.main,
      textDecorationColor: theme.palette.secondary.main,
      marginLeft: 5,
    },
  })
);
