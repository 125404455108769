import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      padding: "35px 35px",
      boxShadow: "0px 7px 14px 0px #00055226",
      borderRadius: "8px",
      marginBottom: 26,
      maxWidth: "660px",
      minWidth: "660px",
      marginLeft: 50,
      "@media (max-width: 800px)": {
        height: "auto",
        padding: "15px 15px",
        marginLeft: 50,
      },
      "@media (max-width: 760px)": {
        minWidth: "560px",
        maxWidth: "560px",
        height: "auto",
        padding: "15px 15px",
        margin: "auto auto 25px 30px",
      },
      "@media (max-width: 600px)": {
        minWidth: "88%",
        maxWidth: "94%",
        height: "auto",
        padding: "15px 15px",
        margin: "auto auto 17px auto",
      },
    },
    header: {
      textAlign: "left",
      width: "410px",
      marginBottom: 40,
      "@media (max-width: 600px)": {
        width: "98%",
        marginBottom: 15,
      },
    },
  })
);
