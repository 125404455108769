import { Autocomplete, Box, Paper, TextField, Typography } from "@mui/material";
import { useAutocomplete } from "../../hooks/useAutocomplete";
import { PredictionType } from "../../interfaces/autocomplete/autocomplete";
import { IHomeCleaningForm, ValueOf } from "../../contexts";
import { Dispatch, MutableRefObject, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOuside";
import "../AutocompleteInputField/autocompleteInputField.css";

type AutocompleteInputFieldProps = {
  name: Partial<keyof IHomeCleaningForm>;
  type: string;
  label: string;
  setFormValues: (
    key: Partial<keyof IHomeCleaningForm>,
    value: Partial<ValueOf<IHomeCleaningForm>>
  ) => void;
  homeCleaningFormValues: IHomeCleaningForm;
  error: boolean;
  errorHelperText?: string;
  setCheck?: Dispatch<React.SetStateAction<boolean>>;
  formattedAddressForUser: string;
  setFormattedAddressForUser: Dispatch<React.SetStateAction<string>>;
};

export const AutocompleteInputField = ({
  name,
  type,
  label,
  setFormValues,
  homeCleaningFormValues,
  error,
  errorHelperText,
  setCheck,
  formattedAddressForUser,
  setFormattedAddressForUser,
}: AutocompleteInputFieldProps) => {
  // const [openedAutocomplete, setOpenedAutocomplete] = useState<boolean>(false);
  // const { value, inputValue, suggested, handleInputChange, handleChange } =
  //   useAutocomplete(name, type, setFormValues);
  // const refAutocomplete: MutableRefObject<HTMLElement | null> = useRef(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  // useOnClickOutside(refAutocomplete, () => setOpenedAutocomplete(false));

  useEffect(() => {
    if (inputRef.current) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["address"],
          componentRestrictions: { country: "SE" }, // Restrict to Sweden
          fields: [
            "address_components",
            "geometry",
            "name",
            "formatted_address",
          ],
        } // You can specify the types here
      );

      autoComplete.addListener("place_changed", () => {
        const place = autoComplete.getPlace();
        var street = "";
        var streetNumber = "";
        var city = "";
        var postalCode = "";

        // Process each address component
        place.address_components &&
          place.address_components.forEach(function (component) {
            var types = component.types;

            if (types.includes("route")) {
              street = component.long_name;
            } else if (types.includes("street_number")) {
              streetNumber = component.long_name;
            } else if (types.includes("postal_town")) {
              city = component.long_name;
            } else if (types.includes("postal_code")) {
              postalCode = component.long_name.replace(/\s/g, "");
            }
          });
        var addressParts = [];

        if (street && streetNumber) {
          addressParts.push(`${street} ${streetNumber}`);
          setFormValues("address", `${street} ${streetNumber}`);
        } else if (street) {
          addressParts.push(street);
          setFormValues("address", street);
        }
        // if (streetNumber) addressParts.push(streetNumber);
        if (postalCode && city) {
          addressParts.push(`${postalCode} ${city}`);
        } else if (city) {
          // Include city even if postalCode is missing
          addressParts.push(city);
        } else if (postalCode) {
          // Include postalCode if city is missing
          addressParts.push(postalCode);
        }

        let formatAddress = addressParts.join(", ");
        setFormattedAddressForUser(formatAddress);
        // setFormValues("address", formatAddress);
        setFormValues("city", city);
        setFormValues("postalCode", postalCode);
        setCheck && setCheck(true);
      });
    }
  }, []);

  const handleChangeValue = (e: any) => {
    setFormattedAddressForUser(e.target.value);
    // setFormValues("address", e.target.value);
    !e.target.value && setFormValues("address", "");
    !e.target.value && setFormValues("city", "");
    !e.target.value && setFormValues("postalCode", "");
    !e.target.value && setCheck && setCheck(false);
  };

  return (
    <Box>
      <input
        id="autocomplete"
        ref={inputRef}
        placeholder="Gatuadress"
        style={{ border: error ? "1px solid #d32f2f" : "1px solid #c1c1c1" }}
        value={formattedAddressForUser} // homeCleaningFormValues.address
        onChange={(e) => handleChangeValue(e)}
      />
      {error && errorHelperText && (
        <Box
          sx={{
            paddingLeft: "15px",
            paddingRight: "10px",
            width: "100%",
          }}
        >
          <Typography color={"error"}>
            {errorHelperText?.substring(0, errorHelperText.length - 17)}
            <a
              href="https://www.bettysstad.se/kontakt/"
              style={{ color: "#d32f2f" }}
            >
              {errorHelperText?.substring(
                errorHelperText.length - 17,
                errorHelperText.length
              )}
            </a>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
