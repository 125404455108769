import React from 'react';
import { debounce } from 'lodash';

import { IUsageContext, UsageContext } from './context';
import { userStartedForm, userVisitedPage, userSubmittedForm } from './helpers';

interface IUsageProvider {
  children: React.ReactNode;
}

const UsageProvider = ({ children }: IUsageProvider) => {
  // Apply debounce to userStartedForm function
  const debouncedUserStartedForm = debounce(userStartedForm, 300);
  // Apply debounce to userVisitedPage function
  const debouncedUserVisitedPage = debounce(userVisitedPage, 300);
  // Apply debounce to userSubmittedForm function
  const debouncedUserSubmittedForm = debounce(userSubmittedForm, 300);
  const config: IUsageContext = {
    userStartedForm: debouncedUserStartedForm,
    userVisitedPage: debouncedUserVisitedPage,
    userSubmittedForm: debouncedUserSubmittedForm,
  };

  return (
    <UsageContext.Provider value={config}>{children}</UsageContext.Provider>
  );
};

export { UsageProvider };
