import React from "react";
import { Box, StepIconProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StepIconStyledComponent = styled(Box)<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: "#9FADCA",
  zIndex: 1,
  color: theme.palette.background.paper,
  fontSize: "1.5rem",
  fontWeight: 700,
  minWidth: "36px",
  maxWidth: "88px",
  height: "36px",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: theme.palette.secondary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.secondary.main,
  }),
  [theme.breakpoints.only("xs")]: {
    width: 32,
    height: 32,
    fontSize: "1.2rem",
  },
}));

export const StepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  const icons: { [index: string]: string } = {
    1: "1",
    2: "2",
    3: "3",
  };
  return (
    <StepIconStyledComponent
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </StepIconStyledComponent>
  );
};
