import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import { formatUserInputDate } from "../../helpers";
import { ITimeGap } from "../../types";
import useStyles from "./styles";

interface ICalendarItem {
  timeGap: ITimeGap;
  chosenStartDate?: string;
  chosenStartTime?: string;
  handleClick: (timeGap: ITimeGap) => void;
}

export const CalendarItem = ({
  chosenStartDate,
  chosenStartTime,
  timeGap,
  handleClick,
}: ICalendarItem) => {
  const classes = useStyles();
  return (
    // <Grid item xs={4} sm={3} md={2} lg="auto">
    <Grid item>
      <Box
        className={`${classes.item} ${
          chosenStartDate === timeGap.startDate.toString() &&
          chosenStartTime === timeGap.startTime
            ? classes.choosenItem
            : classes.notChoosenItem
        }`}
        onClick={() => handleClick(timeGap)}
      >
        <Typography
          color={
            chosenStartDate === timeGap.startDate.toString() &&
            chosenStartTime === timeGap.startTime
              ? "white"
              : "#999999"
          }
        >
          {timeGap.startTime}
        </Typography>
      </Box>
    </Grid>
  );
};
