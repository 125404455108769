import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    container: {
      display: "flex",
    },
    step: {
      flexDirection: "column",
      display: "flex",
      marginBottom: 91,
      marginLeft: 25.5,
      [theme.breakpoints.only("xs")]: {
        marginBottom: 43,
      },
    },
    stepLabel: {
      marginTop: "-85px",
      marginBottom: "-50px",
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
    },
    active: {
      fontWeight: "700 !important",
      color: `${theme.palette.secondary.main} !important`,
    },
    disabled: {
      fontWeight: "400 !important",
      color: "#585858",
    },
  })
);
