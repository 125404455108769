import axios from "axios";

export const getServiceInfo = async () => {
  try {
    const res: any = await axios.get(
      "https://www.bettysstad.se/wp-json/timewave/v1/data"
    );
    console.log("Success fetch service info: ", res);
    return res;
  } catch (err: any) {
    console.log("Error services info", err);
  }
};
