import ReactGA from "react-ga4";

const initializeGA4 = () => {
  ReactGA.initialize(
    `${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}`
  ); // Replace with your Measurement ID
};

const trackPageView = (path: string) => {
  ReactGA.send({ hitType: "page_view", page: path });
};

const trackEvent = (category: string, action: string, label?: string) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export { initializeGA4, trackPageView, trackEvent };
