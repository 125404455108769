import React, { Dispatch, useEffect } from "react";

import stringData from "../../../assets/data/formStringsData.json";
import { meetingTypes } from "../../../assets";
import { useFormContext } from "../../../hooks";
import { Calendar } from "../../Calendar";
import { FormSection } from "../../FormSection";
import { Box } from "@mui/material";
import { getRightActiveStep } from "../../Calendar/helpers";
interface IMeetingInformation {
  handleScrollToButton: () => void;
  activeStepId: any;
  setActiveStepId: any;
  setIsCalendarDataFetched: Dispatch<React.SetStateAction<boolean>>;
  setIsFinishedSecondForm: Dispatch<React.SetStateAction<boolean>>;
}
export const MeetingInformation = ({
  handleScrollToButton,
  activeStepId,
  setActiveStepId,
  setIsCalendarDataFetched,
  setIsFinishedSecondForm,
}: IMeetingInformation) => {
  const { homeCleaningFormValues, validateFields } = useFormContext();
  const meetingInformationString = stringData.steps.meetingInformation;
  let formHeight = document.getElementById("meetingForm");
  let meetingFormHeight = formHeight && formHeight.offsetHeight;
  let width = window.innerWidth;

  useEffect(() => {
    if (homeCleaningFormValues.startTime && validateFields(1)) {
      setActiveStepId(getRightActiveStep(homeCleaningFormValues));
      setIsFinishedSecondForm(true);
    }
  }, [homeCleaningFormValues.startTime]);

  return (
    <Box display={"flex"} flexDirection={"row"} width={"100%"}>
      {width > 800 && (
        <hr
          style={{
            marginTop: "55px",
            marginLeft: "-25px",
            borderLeftWidth: "8px",
            width: 0,
            borderLeftStyle: "dotted",
            borderLeftColor: activeStepId > 1 ? "#1F3157" : "#9FADCA",
            borderTop: "none",
            borderRight: "none",
            borderBottom: "none",
            height: `${meetingFormHeight ? meetingFormHeight + 125 : 135}px`,
          }}
        />
      )}
      <FormSection title={meetingInformationString.calendar.title}>
        {meetingTypes.map(
          (meetingType, index) =>
            meetingType.type === homeCleaningFormValues.meetingType && (
              <Box id="meetingForm" key={index} ml={width < 420 ? -1.5 : 0}>
                <Calendar
                  key={index}
                  gapLengthHours={meetingType.gapLengthHours}
                  meetingType={meetingType.type}
                  apiErrorText={meetingInformationString.calendar.apiErrorText}
                  apiErrorTextUppsala={
                    meetingInformationString.calendar.apiErrorTextUppsala
                  }
                  noSelectedDateErrorText={
                    meetingInformationString.calendar.noSelectedDateErrorText
                  }
                  forwardPaginationText={
                    meetingInformationString.calendar.forwardPaginationText
                  }
                  backwardsPaginationText={
                    meetingInformationString.calendar.backwardsPaginationText
                  }
                  handleScrollToButton={handleScrollToButton}
                  bookThroughOldForm={
                    meetingInformationString.calendar.bookThroughOldForm
                  }
                  oldFormUrl={meetingInformationString.calendar.oldFormUrl}
                  here={meetingInformationString.calendar.here}
                  activeStepId={activeStepId}
                  setIsCalendarDataFetched={setIsCalendarDataFetched}
                />
              </Box>
            )
        )}
      </FormSection>
    </Box>
  );
};
