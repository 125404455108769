import React, { useEffect, useState, useRef } from "react";
import { Box, CircularProgress, Link, Typography } from "@mui/material";
import { useResizeDetector } from "react-resize-detector";

import { CustomStepper } from "../CustomStepper";
import { ButtonGroup } from "../ButtonGroup";
import stringData from "../../assets/data/formStringsData.json";
import {
  ContactInformation,
  ResidenceInformation,
  MeetingInformation,
} from "../steps";
import { useFormContext, useUsageContext } from "../../hooks";
import useStyles from "./styles";
import {
  GAAction,
  GACategory,
  userSubmittedForm,
} from "../../contexts/usage/helpers";
import { Header } from "../Header/Header";
import { Klart } from "../steps/Klart";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CalendarIcon,
  EmailIcon,
  HomeIcon,
  MapPinIcon,
  PhoneIcon,
  UserIcon,
} from "../icons";
import { formatDisplayDate } from "../Calendar/helpers";
import { initializeGA4, trackPageView, trackEvent } from "../../tracking/ga4";

declare var fbq: Function;

export const Form = () => {
  const classes = useStyles();
  const {
    submitForm,
    formSubmissionState,
    homeCleaningFormValues,
    timewaveInformationState,
    sendConfirmationEmails,
    setFormValues,
  } = useFormContext();
  const buttonRef = useRef<HTMLDivElement>(null);
  const formString = stringData.form;
  const { height, ref } = useResizeDetector();
  // const { userStartedForm, userVisitedPage } = useUsageContext();
  const [activeStepId, setActiveStepId] = useState(0);
  // const [prevStepId, setPrevStepId] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  // const [showErrormessage, setShowErrorMessage] = useState(false);
  let width = window.innerWidth;

  const [isCalendarDataFetched, setIsCalendarDataFetched] =
    useState<boolean>(false);
  const [isFinishedFirstForm, setIsFinishedFirtsForm] =
    useState<boolean>(false);
  const [isFinishedSecondForm, setIsFinishedSecondForm] =
    useState<boolean>(false);

  const [isMobileWindowOpen, setIsMobileWindowOpen] = useState<boolean>(false);

  const [formattedAddressForUser, setFormattedAddressForUser] =
    useState<string>("");

  const toggleMobileWindow = () => {
    setIsMobileWindowOpen(!isMobileWindowOpen);
  };

  const handleDisplayErrorMessage = (boolean: boolean) => {
    setDisplayErrorMessage(boolean);
  };

  const handleScrollToButton = () => {
    if (window.parent) {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        if (window.top) {
          window.top.postMessage(
            {
              task: "scroll_to_button",
              offset: rect.top,
            },
            formString.parentUrl
          );
        } else {
          window.parent.postMessage(
            {
              task: "scroll_to_button",
              offset: rect.top,
            },
            formString.parentUrl
          );
        }
      }
    }
  };

  useEffect(() => {
    if (formSubmissionState.data) {
      setIsSubmitted(true);
      sendConfirmationEmails();
      userSubmittedForm(GACategory.HOME_FORM);
      fbq("track", "CompleteRegistration");
      trackEvent("Form", "Submit", "CompleteRegistration");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmissionState]);

  useEffect(() => {
    initializeGA4();
    trackPageView("/"); // Track the initial page view
    setActiveStepId(0);
    setIsFinishedFirtsForm(false);
    setIsCalendarDataFetched(false);
    setIsFinishedSecondForm(false);
  }, []);

  useEffect(() => {
    let secondFormIntoView = document.getElementById("second-form");
    let thirdFormIntoView = document.getElementById("third-form");
    if (
      isFinishedFirstForm &&
      isCalendarDataFetched &&
      secondFormIntoView &&
      !isFinishedSecondForm
    ) {
      secondFormIntoView.scrollIntoView({ behavior: "smooth" });
    }
    if (isFinishedSecondForm && thirdFormIntoView) {
      thirdFormIntoView.scrollIntoView({ behavior: "smooth" });
    }
  }, [isFinishedFirstForm, isCalendarDataFetched, isFinishedSecondForm]);

  //for mobile smooth scroll
  useEffect(() => {
    let secondFormIntoView = document.getElementById("second-form-mobile");
    let thirdFormIntoView = document.getElementById("third-form-mobile");
    if (
      isFinishedFirstForm &&
      isCalendarDataFetched &&
      secondFormIntoView &&
      !isFinishedSecondForm &&
      width < 800
    ) {
      secondFormIntoView.scrollIntoView({ behavior: "smooth" });
    }
    if (isFinishedSecondForm && thirdFormIntoView && width < 800) {
      thirdFormIntoView.scrollIntoView({ behavior: "smooth" });
    }
  }, [isFinishedFirstForm, isCalendarDataFetched, isFinishedSecondForm]);

  const steps = [
    {
      label: "Om din bostad",
      component: (
        <ResidenceInformation
          activeStepId={activeStepId}
          setActiveStepId={setActiveStepId}
          setIsFinishedForm={setIsFinishedFirtsForm}
          isCalendarDataFetched={isCalendarDataFetched}
          formattedAddressForUser={formattedAddressForUser}
          setFormattedAddressForUser={setFormattedAddressForUser}
        />
      ),
    },
    {
      label: "Mötestid",
      component: (
        <div id="second-form">
          <MeetingInformation
            handleScrollToButton={handleScrollToButton}
            activeStepId={activeStepId}
            setActiveStepId={setActiveStepId}
            setIsCalendarDataFetched={setIsCalendarDataFetched}
            setIsFinishedSecondForm={setIsFinishedSecondForm}
          />
        </div>
      ),
    },
    {
      label: "Kontaktuppgifter",
      component: (
        <div id="third-form">
          <ContactInformation
            activeStepId={activeStepId}
            setActiveStepId={setActiveStepId}
          />
        </div>
      ),
    },
  ];

  if (formSubmissionState.loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <CircularProgress size={65} />
      </div>
    );
  }

  return (
    <Box className={classes.root}>
      <Box ref={ref}>
        {isSubmitted && (
          <Box textAlign={"center"} padding={"15% 0px"}>
            <Typography variant="h1" marginBottom={"15px"}>
              {stringData.thankYou.heading}
            </Typography>
            <Typography variant="body1">{stringData.thankYou.text}</Typography>
            <Typography variant="body1">
              {stringData.thankYou.subHeading}
            </Typography>
          </Box>
        )}
        <Header />
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={{ xs: "center", lg: "end", xl: "end" }}
          mt={width > 800 ? 17 : -5.5}
          maxWidth={width > 599 ? "96%" : "100%"}
        >
          <Box
            className={classes.customStepperDiv}
            mr={{
              xs: 0,
              lg:
                homeCleaningFormValues.address ||
                homeCleaningFormValues.sqmSize ||
                activeStepId > 0
                  ? "28.5%"
                  : "auto",
            }}
            ml={
              homeCleaningFormValues.address ||
              homeCleaningFormValues.sqmSize ||
              activeStepId > 0
                ? 0
                : "auto"
            }
          >
            <CustomStepper
              activeStep={activeStepId}
              steps={steps}
              orientation="vertical"
            />
            <Box mt={-11} mb={12}>
              <Klart
                activeStepId={activeStepId}
                isApproved={isApproved}
                setIsApproved={setIsApproved}
              />
            </Box>

            <Box>
              <ButtonGroup
                activeStep={activeStepId}
                stepsLength={steps.length}
                displayErrorMessage={displayErrorMessage}
                handleDisplayErrorMessage={(boolean) =>
                  handleDisplayErrorMessage(boolean)
                }
                submitForm={submitForm}
                isApproved={isApproved}
                setActiveStepId={setActiveStepId}
                setIsApproved={setIsApproved}
              />
              <Box sx={{ height: 100 }} />
            </Box>
          </Box>
          <Box className={classes.onlyComponentsDiv}>
            <div>
              <ResidenceInformation
                activeStepId={activeStepId}
                setActiveStepId={setActiveStepId}
                setIsFinishedForm={setIsFinishedFirtsForm}
                isCalendarDataFetched={isCalendarDataFetched}
                formattedAddressForUser={formattedAddressForUser}
                setFormattedAddressForUser={setFormattedAddressForUser}
              />
            </div>
            <div id="second-form-mobile">
              <MeetingInformation
                handleScrollToButton={handleScrollToButton}
                activeStepId={activeStepId}
                setActiveStepId={setActiveStepId}
                setIsCalendarDataFetched={setIsCalendarDataFetched}
                setIsFinishedSecondForm={setIsFinishedSecondForm}
              />
            </div>
            <div id="third-form-mobile">
              <ContactInformation
                activeStepId={activeStepId}
                setActiveStepId={setActiveStepId}
              />
            </div>
            <div>
              <Klart
                activeStepId={activeStepId}
                isApproved={isApproved}
                setIsApproved={setIsApproved}
              />
            </div>
            <ButtonGroup
              activeStep={activeStepId}
              stepsLength={steps.length}
              displayErrorMessage={displayErrorMessage}
              handleDisplayErrorMessage={(boolean) =>
                handleDisplayErrorMessage(boolean)
              }
              submitForm={submitForm}
              isApproved={isApproved}
              setActiveStepId={setActiveStepId}
              setIsApproved={setIsApproved}
            />
            <Box sx={{ height: 100 }} />
          </Box>

          {(homeCleaningFormValues.address ||
            homeCleaningFormValues.sqmSize ||
            activeStepId > 0) && (
            <Box
              minWidth={"315px"}
              maxWidth={"315px"}
              className={classes.windowElement}
              display={{ xs: "none", lg: "flex" }}
              flexDirection={"column"}
            >
              <Box className={classes.topPartCard}>
                <Typography variant="h2" color={"#FFFFFF"} ml={2}>
                  Översikt
                </Typography>
                <Typography variant="body2" color={"#FFFFFF"} ml={2}>
                  Återkommande Hemstädning
                </Typography>
              </Box>
              <Box className={classes.bottomPartCard}>
                {(homeCleaningFormValues.firstName ||
                  homeCleaningFormValues.lastName) && (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    paddingLeft={1.5}
                    paddingTop={1.5}
                  >
                    <UserIcon />
                    <Typography ml={1.5} fontWeight={700} color={"#1F3157"}>
                      {`${homeCleaningFormValues.firstName} ${homeCleaningFormValues.lastName}`}
                    </Typography>
                  </Box>
                )}
                {homeCleaningFormValues.phone && (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    paddingLeft={1.5}
                    paddingTop={1.5}
                  >
                    <PhoneIcon viewBox="0 0 16 16" />
                    <Typography ml={1.5}>
                      {homeCleaningFormValues.phone}
                    </Typography>
                  </Box>
                )}
                {homeCleaningFormValues.email && (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    paddingLeft={1.5}
                    paddingTop={1.5}
                  >
                    <EmailIcon />
                    <Typography ml={1.5}>
                      {homeCleaningFormValues.email}
                    </Typography>
                  </Box>
                )}
                {homeCleaningFormValues.startTime && (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    paddingLeft={1.5}
                    paddingTop={1.5}
                  >
                    <CalendarIcon />
                    <Typography ml={1.5} color={"#1F3157"}>
                      {`${formatDisplayDate(
                        homeCleaningFormValues.startDate
                      )}, ${homeCleaningFormValues.startTime}`}
                    </Typography>
                  </Box>
                )}
                {formattedAddressForUser && (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    paddingLeft={1.5}
                    paddingTop={1.5}
                  >
                    <MapPinIcon />
                    <Typography ml={1.5} color={"#1F3157"}>
                      {formattedAddressForUser}
                    </Typography>
                  </Box>
                )}
                {homeCleaningFormValues.sqmSize && (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    paddingLeft={1.5}
                    paddingTop={1.5}
                  >
                    <HomeIcon />
                    <Typography ml={1.5} color={"#1F3157"}>
                      {`${homeCleaningFormValues.sqmSize}m²`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}

          {(homeCleaningFormValues.address ||
            homeCleaningFormValues.sqmSize ||
            activeStepId > 0) && (
            <Box
              width={"100%"}
              // width={"660px"}
              className={classes.windowElementMobile}
              display={{ xs: "flex", lg: "none" }}
              flexDirection={"column"}
            >
              <Box className={classes.topPartCardMobile}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h2" color={"#FFFFFF"} ml={2}>
                    Översikt
                  </Typography>
                  <Typography variant="body2" color={"#FFFFFF"} ml={2}>
                    Återkommande Hemstädning
                  </Typography>
                </Box>
                <Box mr={2} sx={{ cursor: "pointer" }}>
                  {isMobileWindowOpen ? (
                    <ArrowDownIcon onClick={toggleMobileWindow} />
                  ) : (
                    <ArrowUpIcon onClick={toggleMobileWindow} />
                  )}
                </Box>
              </Box>
              {isMobileWindowOpen && (
                <Box className={classes.bottomPartCardMobile}>
                  {(homeCleaningFormValues.firstName ||
                    homeCleaningFormValues.lastName) && (
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      paddingLeft={1.5}
                      paddingTop={1.5}
                    >
                      <UserIcon />
                      <Typography ml={1.5} fontWeight={700} color={"#1F3157"}>
                        {`${homeCleaningFormValues.firstName} ${homeCleaningFormValues.lastName}`}
                      </Typography>
                    </Box>
                  )}
                  {homeCleaningFormValues.phone && (
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      paddingLeft={1.5}
                      paddingTop={1.5}
                    >
                      <PhoneIcon viewBox="0 0 16 16" />
                      <Typography ml={1.5}>
                        {homeCleaningFormValues.phone}
                      </Typography>
                    </Box>
                  )}
                  {homeCleaningFormValues.email && (
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      paddingLeft={1.5}
                      paddingTop={1.5}
                    >
                      <EmailIcon />
                      <Typography ml={1.5}>
                        {homeCleaningFormValues.email}
                      </Typography>
                    </Box>
                  )}
                  {homeCleaningFormValues.startTime && (
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      paddingLeft={1.5}
                      paddingTop={1.5}
                    >
                      <CalendarIcon />
                      <Typography ml={1.5} color={"#1F3157"}>
                        {`${formatDisplayDate(
                          homeCleaningFormValues.startDate
                        )}, ${homeCleaningFormValues.startTime}`}
                      </Typography>
                    </Box>
                  )}
                  {formattedAddressForUser && (
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      paddingLeft={1.5}
                      paddingTop={1.5}
                    >
                      <MapPinIcon />
                      <Typography ml={1.5} color={"#1F3157"}>
                        {formattedAddressForUser}
                      </Typography>
                    </Box>
                  )}
                  {homeCleaningFormValues.sqmSize && (
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      paddingLeft={1.5}
                      paddingTop={1.5}
                    >
                      <HomeIcon />
                      <Typography ml={1.5} color={"#1F3157"}>
                        {`${homeCleaningFormValues.sqmSize}m²`}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>

        {formSubmissionState.error && (
          <>
            <Typography className={classes.errorText}>
              {formString.formSubmissionErrorText}
            </Typography>
            <Box className={classes.linkContainer}>
              <Typography className={classes.link}>
                {formString.bookThroughOldForm}
              </Typography>
              <Link
                target="_blank"
                href={formString.oldFormUrl}
                className={classes.link}
              >
                {formString.here}
              </Link>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
