import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: "#1F3157",
      width: "116px",
      height: "43px",
      borderRadius: "82px",
      cursor: "pointer",
      border: "none",
    },
    text: {
      color: "#FFFFFF",
    },
  })
);
