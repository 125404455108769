import { Box, Grid, InputLabel, Typography } from "@mui/material";
import { FormSection } from "../../FormSection";
import { Input } from "../../Input";
import stringData from "../../../assets/data/formStringsData.json";
import { useFormContext } from "../../../hooks";

import { AutocompleteInputField } from "../../AutocompleteInputField/AutocompleteInputField";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import { getRightActiveStep } from "../../Calendar/helpers";

export const ResidenceInformation = ({
  activeStepId,
  setActiveStepId,
  setIsFinishedForm,
  isCalendarDataFetched,
  formattedAddressForUser,
  setFormattedAddressForUser,
}: any) => {
  const {
    setFormValues,
    fieldErrors,
    homeCleaningFormValues,
    validateFields,
    timewaveInformationState,
  } = useFormContext();
  const residenceInformationStrings = stringData.steps.residenceInformation;
  const classes = useStyles();
  let formHeight = document.getElementById("residenceForm");
  let residenceFormHeight = formHeight && formHeight.offsetHeight;
  const [isPostalCodeGood, setIsPostalCodeGood] = useState<boolean>(false);
  let width = window.innerWidth;
  const [check, setCheck] = useState<boolean>(false);
  const params = new URLSearchParams(window.location.search);
  const swedishPostalCodePattern = /^(\d{3} \d{2}|\d{5})$/;
  const [noPostalCodeValue, setNoPostalCodeValue] = useState<string>("");

  useEffect(() => {
    if (
      homeCleaningFormValues.address &&
      homeCleaningFormValues.sqmSize &&
      homeCleaningFormValues.postalCode &&
      validateFields(0) &&
      isPostalCodeGood &&
      timewaveInformationState.employeeIdsConfig.responsibleEmployeeId
    ) {
      setActiveStepId(getRightActiveStep(homeCleaningFormValues));
      setIsFinishedForm(true);
    }
  }, [
    homeCleaningFormValues.address,
    homeCleaningFormValues.sqmSize,
    isPostalCodeGood,
    homeCleaningFormValues.postalCode,
    timewaveInformationState.employeeIdsConfig,
  ]);

  useEffect(() => {
    const formData = {
      houseSize: params.get("houseSize"),
      houseZipcode: params.get("houseZipcode"),
      houseAddress: params.get("houseAddress"),
      houseCity: params.get("houseCity"),
    };
    formData.houseSize && setFormValues("sqmSize", formData.houseSize);
    formData.houseAddress && setFormValues("address", formData.houseAddress);
    formData.houseCity && setFormValues("city", formData.houseCity);

    if (formData.houseZipcode) {
      const postalCodeWithoutSpace = formData.houseZipcode.replace(/\s/g, "");
      setFormValues("postalCode", postalCodeWithoutSpace);
      checkIfPostalCodeIsGood(postalCodeWithoutSpace);
    } else if (formData.houseAddress && !formData.houseZipcode) {
      setFormattedAddressForUser(`${formData.houseAddress}`);
    }
  }, []);

  const checkIfPostalCodeIsGood = (postalCode: string) => {
    if (
      // postalCode &&
      !isNaN(+postalCode) &&
      ((+postalCode > 40009 && +postalCode <= 44971) ||
        (+postalCode > 10003 && +postalCode <= 19588) ||
        (+postalCode > 74192 && +postalCode <= 75760))
    ) {
      setIsPostalCodeGood(true);
    } else {
      setIsPostalCodeGood(false);
    }
  };

  useEffect(() => {
    if (
      (check && homeCleaningFormValues.address) ||
      (homeCleaningFormValues.address && homeCleaningFormValues.city)
    ) {
      checkIfPostalCodeIsGood(homeCleaningFormValues.postalCode);
    }
  }, [
    homeCleaningFormValues.address,
    check,
    homeCleaningFormValues.postalCode,
    homeCleaningFormValues.city,
  ]);

  const handleChangePostalCode = (e: any) => {
    swedishPostalCodePattern.test(e.trim()) &&
      checkIfPostalCodeIsGood &&
      checkIfPostalCodeIsGood(e);
    setNoPostalCodeValue(e);
  };

  useEffect(() => {
    let address = homeCleaningFormValues.address;
    const fiveNumbersRegex = /\d{5}/;
    if (isPostalCodeGood && !homeCleaningFormValues.postalCode) {
      setFormValues("postalCode", noPostalCodeValue);
      // setFormValues("address", `${address}, ${noPostalCodeValue}`);
      setFormattedAddressForUser(`${address}, ${noPostalCodeValue}`);
    }
    if (
      isPostalCodeGood &&
      homeCleaningFormValues.postalCode &&
      (!fiveNumbersRegex.test(address) ||
        params.get("houseZipcode") !== homeCleaningFormValues.postalCode)
    ) {
      setFormattedAddressForUser(
        `${address}, ${homeCleaningFormValues.postalCode}`
      );
    }
    if (
      isPostalCodeGood &&
      homeCleaningFormValues.postalCode &&
      fiveNumbersRegex.test(address)
    ) {
      setFormattedAddressForUser(`${address}`);
    }
  }, [isPostalCodeGood]);

  return (
    <Box display={"flex"} flexDirection={"row"} width={"100%"}>
      {width > 800 && (
        <hr
          style={{
            marginTop: "55px",
            marginLeft: "-25px",
            borderLeftWidth: "8px",
            width: 0,
            borderLeftStyle: "dotted",
            borderLeftColor: activeStepId > 0 ? "#1F3157" : "#9FADCA",
            borderTop: "none",
            borderRight: "none",
            borderBottom: "none",
            height: `${residenceFormHeight}px`,
          }}
        />
      )}
      <FormSection title="">
        <Grid container spacing={1} id="residenceForm">
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={"space-between"}
          >
            <Typography
              fontWeight={750}
              variant="h5"
              display={{ xs: "flex", sm: "none" }}
              mb={1}
            >
              Några fler steg
            </Typography>
            <Grid
              item
              xs={12}
              sm={3}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              mb={{ xs: 1, sm: 0 }}
            >
              <Box className={classes.check} />
              <Typography variant="body2" ml={{ xs: 1, sm: 2 }}>
                Nya kunder 139kr/h
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              mb={{ xs: 1, sm: 0 }}
            >
              <Box className={classes.check} />
              <Typography variant="body2" ml={{ xs: 1, sm: 2 }}>
                Inga bindningstider
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              mb={{ xs: 1, sm: 0 }}
            >
              <Box className={classes.check} />
              <Typography variant="body2" ml={{ xs: 1, sm: 2 }}>
                Boka av städ kostnadsfritt
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            mt={"10px"}
            mb={"35px"}
            display={{ xs: "none", sm: "flex" }}
            flexDirection={"column"}
          >
            <Typography
              color={"#1F3157"}
              fontWeight={750}
              sx={{ fontSize: "40px !important" }}
              lineHeight={"50px"}
            >
              Nu är du snart klar!
            </Typography>
            <Typography
              color={"#77839D"}
              sx={{ fontSize: "20.5px !important" }}
              lineHeight={"25px"}
            >
              Boka en tid när det passar dig, få offert direkt!
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom={"-5px"} mt={{ xs: 1, sm: 0 }}>
            <Typography color={"#1F3157"} fontWeight={750} variant="h5">
              Om din bostad
            </Typography>
          </Grid>
          <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            ml={1}
            mr={1}
            mt={1}
          >
            <Grid item xs={12} sm={12} md={5.8} mt={{ xs: 1, sm: 0 }}>
              <InputLabel sx={{ color: "#77839D", marginBottom: "3px" }}>
                {
                  residenceInformationStrings.residenceInformation.fields
                    .sqmSize.placeholder
                }
              </InputLabel>
              <Input
                placeholder={
                  residenceInformationStrings.residenceInformation.fields
                    .sqmSize.placeholder
                }
                error={fieldErrors.sqmSize}
                errorHelperText={
                  residenceInformationStrings.residenceInformation.fields
                    .sqmSize.errorHelperText
                }
                key={"sqmSize"}
                value={homeCleaningFormValues["sqmSize"]}
                onChange={(value) => {
                  setFormValues("sqmSize", value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5.8} mt={{ xs: 1, sm: 0 }}>
              <InputLabel sx={{ color: "#77839D", marginBottom: "3px" }}>
                {
                  residenceInformationStrings.residenceInformation.fields
                    .address.placeholder
                }
              </InputLabel>
              <AutocompleteInputField
                name="address"
                type="address"
                label={
                  residenceInformationStrings.residenceInformation.fields
                    .address.placeholder
                }
                setFormValues={setFormValues}
                homeCleaningFormValues={homeCleaningFormValues}
                error={
                  fieldErrors.address ||
                  (!!homeCleaningFormValues.postalCode &&
                    !isPostalCodeGood &&
                    (!!homeCleaningFormValues.city ||
                      !!params.get("houseAddress")))
                }
                errorHelperText={
                  (!!homeCleaningFormValues.postalCode &&
                    !isPostalCodeGood &&
                    !!homeCleaningFormValues.city) ||
                  !!params.get("houseAddress")
                    ? "Vi städar i Storstockholm och Storgöteborg, om du tillhör dessa regioner ber vi dig kontakta oss här."
                    : ""
                }
                setCheck={setCheck}
                formattedAddressForUser={formattedAddressForUser}
                setFormattedAddressForUser={setFormattedAddressForUser}
              />
            </Grid>
            {!homeCleaningFormValues.postalCode &&
              homeCleaningFormValues.city && (
                <Grid item xs={12} sm={12} md={5.8} mt={{ xs: 1, sm: 0 }}>
                  <InputLabel sx={{ color: "#77839D", marginBottom: "3px" }}>
                    Postnummer
                  </InputLabel>
                  <Input
                    placeholder="Postnummer"
                    error={!isPostalCodeGood && noPostalCodeValue.length > 4}
                    errorHelperText=""
                    value={noPostalCodeValue}
                    onChange={(e) => handleChangePostalCode(e)}
                    type="number"
                  />
                  {!isPostalCodeGood && noPostalCodeValue.length > 4 && (
                    <Typography color={"error"}>
                      Vi städar i Storstockholm och Storgöteborg, om du tillhör
                      dessa regioner ber vi dig
                      <a
                        href="https://www.bettysstad.se/kontakt/"
                        style={{ color: "#d32f2f", marginLeft: "5px" }}
                      >
                        kontakta oss här.
                      </a>
                    </Typography>
                  )}
                </Grid>
              )}
          </Grid>
        </Grid>
        {activeStepId === 1 &&
          !isCalendarDataFetched &&
          isPostalCodeGood &&
          homeCleaningFormValues.postalCode && (
            <Typography mt={3} className={classes.loadingText}>
              Laddar kalenderdata...
            </Typography>
          )}
      </FormSection>
    </Box>
  );
};
