import {
  IContactInformation,
  IFormSubmissionState,
  IHomeCleaningForm,
  IMeetingInformation,
  MeetingTypes,
  ITimewaveInformationState,
} from "./types";

export interface IFormState {
  fieldErrors: Record<
    keyof IContactInformation | keyof IMeetingInformation,
    boolean
  >;
  homeCleaningFormValues: IHomeCleaningForm;
  formSubmissionState: IFormSubmissionState;
  timewaveInformationState: ITimewaveInformationState;
}

export const initialFormState: IFormState = {
  homeCleaningFormValues: {
    meetingType: MeetingTypes.VIDEO,
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    postalCode: "",
    phone: "",
    email: "",
    sqmSize: "",
    meetingEmployeeId: "",
    startDate: "",
    startTime: "",
    endTime: "",
  },
  fieldErrors: {
    firstName: false,
    lastName: false,
    address: false,
    city: false,
    postalCode: false,
    phone: false,
    email: false,
    sqmSize: false,
    startDate: false,
    startTime: false,
    endTime: false,
    meetingEmployeeId: false,
  },
  formSubmissionState: {
    data: false,
    error: false,
    loading: false,
  },
  timewaveInformationState: {
    employeeIdsConfig: {
      responsibleEmployeeId: "",
      physicalMeetingEmployeeIds: { gothenburg: "", stockholm: "" },
      digitalMeetingEmployeeIds: {
        gothenburg: "",
        stockholm: "",
        uppsala: "",
      },
    },
    serviceIdsConfig: {
      physicalMeetingServiceIds: { gothenburg: "", stockholm: "" },
      digitalMeetingServiceIds: { gothenburg: "", stockholm: "", uppsala: "" },
    },
  },
};
